// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EukqnQJoID7oocRD3YdV {
  max-width: 250px;
  overflow-wrap: break-word;
  word-break: break-word;
  word-wrap: break-word;
}

.XkVI85SKjQoojOqestKA {
  transform: scale(1.15);
}

.EukqnQJoID7oocRD3YdV p {
  text-align: left;
}

.DEUERsGjMQjjl_WhcAzP {
  text-align: left;
  display: flex;
  flex-wrap: wrap;
}`, "",{"version":3,"sources":["webpack://./src/components/CompanyAdmin/Candidates/VerificationIcons/VerificationIcons.modules.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,yBAAA;EACA,sBAAA;EACA,qBAAA;AACJ;;AAEA;EACG,sBAAA;AACH;;AAEA;EACE,gBAAA;AACF;;AAGA;EACE,gBAAA;EACA,aAAA;EACA,eAAA;AAAF","sourcesContent":[".customCheckTooltip {\n    max-width: 250px;\n    overflow-wrap: break-word;\n    word-break: break-word;\n    word-wrap: break-word;\n}\n\n.iconScaling {\n   transform: scale(1.15);\n }\n\n.customCheckTooltip p {\n  text-align: left;\n    \n}\n\n.socailMediaTooltip{\n  text-align: left;\n  display: flex;\n  flex-wrap: wrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customCheckTooltip": `EukqnQJoID7oocRD3YdV`,
	"iconScaling": `XkVI85SKjQoojOqestKA`,
	"socailMediaTooltip": `DEUERsGjMQjjl_WhcAzP`
};
export default ___CSS_LOADER_EXPORT___;
