import React from 'react';
import { Card, Form, Button, Col, Row } from 'react-bootstrap';
import { Field } from 'formik';
import { isMobileOnly } from 'react-device-detect';
import { isEmpty } from 'lodash';
import { SOCIAL_MEDIA, SOCIAL_MEDIA_PLACEHOLDER } from '../../../../../utils/commonConstant';
import BgvSecureText from '../../../CommonComponent/BgvSecureText/BgvSecureText';
import BgvGoBackBtn from '../../../CommonComponent/BgvGoBackBtn/BgvGoBackBtn';
import ValidatedFormInputField from '../../../../../core-components/ValidatedFormInputField';
import ProgressBar from '../../../../../core-components/ProgressBar';
import { getTrimmedValue } from '../../../../../utils/utilities';
import styles from './SocialMedia.module.scss';

const SocialMedia = (props) => {
  const {
    values,
    errors,
    handleSubmit,
    handleBlur,
    handleChange,
    setFieldValue,
    setErrors,
    bgv_config
  } = props;

  const isValid = values.social_media_config.every((platform) => {
    const urlKey = `${platform.toLowerCase()}_url`;
    const notAvailableKey = `is_${platform.toLowerCase()}_url_not_available`;
    return (
      (values[notAvailableKey] === false && values[urlKey] !== '') ||
      (values[notAvailableKey] === true && values[urlKey] === '')
    );
  });

  const indexing =
    props?.totalSteps
      .map((val) => {
        return val?.id;
      })
      .indexOf(6) + 1;
  const { social_media } = bgv_config.config;
  const orderedSocialMedia = social_media?.sort(
    (a, b) => Object.keys(SOCIAL_MEDIA).indexOf(a) - Object.keys(SOCIAL_MEDIA).indexOf(b)
  );

  return (
    <Card className={styles.socialMediaCard}>
      <Card.Body className={styles.socialMediaCardBody}>
        {!isMobileOnly ? (
          <div data-testid='no-mobile-header'>
            <span className={styles.socialMediaHeading}>Social Media</span>
            <div className={styles.socialMediaProgressContainer}>
              <span className={styles.stepText}>
                {indexing} of {props.totalSteps.length}
              </span>
              <ProgressBar max={props.totalSteps.length} min={0} now={indexing} />
            </div>
          </div>
        ) : (
          <>
            <BgvSecureText />
            <div className={styles.socialMediaProgressContainerMobile}>
              <span className={styles.socialMediaHeading}>Basic Details</span>
              <span className={styles.stepTextMobile}>
                {indexing} of {this.props.totalSteps.length}
              </span>
            </div>
            <ProgressBar max={this.props.totalSteps.length} min={0} now={indexing} />
          </>
        )}
        <div className={styles.noteContainer}>
          <span className={styles.noteHeading}>Note: </span>
          <span className={styles.noteText}>
            Please ensure your profile is public. If it’s private, our team member, Libin Thomas,
            will send a connection request on your social media accounts.
          </span>
        </div>
        <Form
          onSubmit={handleSubmit}
          className={styles.socialMediaForm}
          data-testid='social-media-form'
        >
          {orderedSocialMedia?.map((platform) => (
            <div className={styles.socialMediaPlatformContainer}>
              <Row key={platform.toLowerCase()}>
                <Col sm={12} xs={12} md={10} lg={8}>
                  <Form.Label>
                    Add your {SOCIAL_MEDIA[platform]} profile URL
                    {!values[`is_${platform.toLowerCase()}_url_not_available`] && <span>*</span>}
                  </Form.Label>
                  <Field
                    data-testid={`social-media-${platform.toLowerCase()}`}
                    type='text'
                    name={`${platform.toLowerCase()}_url`}
                    className={platform.toLowerCase()}
                    component={ValidatedFormInputField}
                    disabled={values[`is_${platform.toLowerCase()}_url_not_available`]}
                    placeholder={SOCIAL_MEDIA_PLACEHOLDER[platform]}
                    onChange={(e) => {
                      const trimmedValue = getTrimmedValue(e.target.value, false);
                      setFieldValue(`${platform.toLowerCase()}_url`, trimmedValue, true);
                    }}
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12} xs={12} md={12} lg={12} className={styles.skipBtn}>
                  <Form.Check
                    type={'checkbox'}
                    className={styles.socialUnavaliableCheckbox}
                    id={`is_${platform.toLowerCase()}_url_not_available`}
                    label={`I don't have a ${SOCIAL_MEDIA[platform]} account`}
                    name={`is_${platform.toLowerCase()}_url_not_available`}
                    checked={values[`is_${platform.toLowerCase()}_url_not_available`]}
                    onChange={(e) => {
                      setFieldValue(
                        `is_${platform.toLowerCase()}_url_not_available`,
                        e.target.checked
                      );
                      if (e.target.checked) {
                        setTimeout(() => {
                          setFieldValue(`${platform.toLowerCase()}_url`, '');
                          let newErrors = { ...errors };
                          delete newErrors[`${platform.toLowerCase()}_url}`];
                          setErrors(newErrors);
                        }, 0);
                      }
                    }}
                  />
                </Col>
              </Row>
            </div>
          ))}
        </Form>
        <Card.Footer className={styles.socialMediaFooter}>
          <Row>
            <Col sm={12} xs={12} md={12} lg={12} className={styles.socialSubmitBtn}>
              {/* Added common prev button */}
              <BgvGoBackBtn handleClick={props.prevStep} />
              <Button
                className={
                  isValid && !props.internalLoading && isEmpty(errors)
                    ? styles.activeBtn
                    : styles.disabledBtn
                }
                disabled={
                  !isValid || props.isLoadingBgvCache || props.internalLoading || !isEmpty(errors)
                }
                onClick={() => {
                  props.handleSubmit();
                }}
              >
                {'Next'}
              </Button>
            </Col>
          </Row>
        </Card.Footer>
      </Card.Body>
    </Card>
  );
};

export default SocialMedia;
