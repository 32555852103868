// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fk4mkhb1a8F7UxJ8OlE8 {
  margin-bottom: 10px;
}

.NwI1WDrIyoEHBCV2RX1m {
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  background-color: inherit;
  min-width: 400px;
}

.a8lNgg4HQ2Nq60JLNZLr {
  margin-right: 8px;
}

.np0GBPeayIjaIt3cvItl {
  margin-right: 8px;
  height: 14px;
  width: 14px;
}

.rtXgpTWXhzvYl1qwjREM {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
}

.MJE94dLYsuwptdckgKCW {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #555555;
}

._cG26JX1N221IiBKJW4g {
  display: flex;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/CompanyAdmin/frontend-common/CreditBuyPackagePage/Packages/PackageListing/ChecksIncluded.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;;AACA;EACE,YAAA;EACA,UAAA;EACA,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,gBAAA;AAEF;;AAAA;EACE,iBAAA;AAGF;;AAAA;EACE,iBAAA;EACA,YAAA;EACA,WAAA;AAGF;;AADA;EACE,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAIF;;AAFA;EACE,kBAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAKF;;AAHA;EACE,aAAA;EACA,mBAAA;AAMF","sourcesContent":[".checkListContainer {\n  margin-bottom: 10px;\n}\n.listGroupItem {\n  border: none;\n  padding: 0;\n  display: flex;\n  align-items: center;\n  background-color: inherit;\n  min-width: 400px;\n}\n.checkSvg {\n  margin-right: 8px;\n}\n\n.checkItemSvg {\n  margin-right: 8px;\n  height: 14px;\n  width: 14px;\n}\n.checkTextHeading {\n  font-style: normal;\n  font-weight: 500;\n  font-size: 14px;\n  line-height: 21px;\n  color: #333333;\n}\n.checkText {\n  font-style: normal;\n  font-weight: normal;\n  font-size: 14px;\n  line-height: 21px;\n  color: #555555;\n}\n.listingText {\n  display: flex;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkListContainer": `fk4mkhb1a8F7UxJ8OlE8`,
	"listGroupItem": `NwI1WDrIyoEHBCV2RX1m`,
	"checkSvg": `a8lNgg4HQ2Nq60JLNZLr`,
	"checkItemSvg": `np0GBPeayIjaIt3cvItl`,
	"checkTextHeading": `rtXgpTWXhzvYl1qwjREM`,
	"checkText": `MJE94dLYsuwptdckgKCW`,
	"listingText": `_cG26JX1N221IiBKJW4g`
};
export default ___CSS_LOADER_EXPORT___;
